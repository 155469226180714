<template>
  <div class="sonContent">
    <div class="SearchBar">
      <div style="display: flex">
        <span style="margin: 6px 10px 0 10px">工程编号/名称:</span>
        <el-input
          style="width: 200px"
          placeholder="请输入"
          size="small"
          v-model="searchBox.projectStr"
        ></el-input>
        <span style="margin: 6px 10px 0 10px">现场管理员：</span>
        <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.siteManager"
        ></el-input>
        <span style="margin: 6px 10px 0 20px">第三方施工队：</span>
        <el-input
          style="width: 200px"
          placeholder="请输入"
          size="small"
          v-model="searchBox.teamName"
        ></el-input>
        <div style="display: flex">
          <span style="margin: 6px 10px 0 20px">{{
            hanleBtn == 1 ? "发料时间：" : "退料时间："
          }}</span>
          <el-date-picker
            size="small"
            v-model="time"
            type="datetimerange"
            @change="timeChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="选择开始日期时间"
            end-placeholder="选择结束日期时间"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
          <el-button
            type="primary"
            class="SearchBtn"
            size="small"
            @click="search"
            style="margin-left: 10px"
            >查询</el-button
          >
        </div>
      </div>
    </div>

    <!--数据表格-->
    <el-table
      ref="tableData"
      :data="tableData"
      style="width: 100%; margin: 15px 0"
      border
      height="calc(100vh - 394px)"
      :stripe="true"
    >
      <el-table-column type="index" label="序号" width="70" align="center">
      </el-table-column>
      <el-table-column
        prop="projectNumber"
        label="工程编号"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="projectName"
        label="工程名称"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="teamName"
        label="第三方施工队"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="siteManager"
        label="现场管理员"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="warehouseStaff"
        label="库管审核人"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="warehouseKeeperTime"
        label="库管审核时间"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        v-if="hanleBtn == 1"
        prop="warehouseStaff"
        label="发料人"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        v-if="hanleBtn == 1"
        prop="warehouseStaffTime"
        label="发料时间"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        v-if="hanleBtn == 2 || hanleBtn == 3"
        prop="warehouseStaff"
        label="退料人"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        v-if="hanleBtn == 2 || hanleBtn == 3"
        prop="warehouseStaffTime"
        label="退料时间"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="操作" align="left" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-button v-if="hanleBtn == 1" @click="showDetil(row)" type="primary"
            >发料明细</el-button
          >
          <el-button
            v-if="hanleBtn == 2 || hanleBtn == 3"
            @click="showDetil(row)"
            type="primary"
            >退料明细</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pageBox">
      <el-pagination
        :current-page="searchBox.current"
        :background="true"
        :page-sizes="[30, 50, 100]"
        :page-size="searchBox.size"
        layout="total, prev, pager, next, sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog title="详情" :visible.sync="detildialogVisible" width="40%">
      <div style="max-height: 600px; overflow: auto" v-if="detildialogVisible">
        <p class="detil-code">申请信息</p>
        <div style="padding: 10px">
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1">工程名称：{{ project.projectName }}</div>
            <div style="flex: 1">第三方施工队：{{ project.teamName }}</div>
          </div>
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1" v-if="hanleBtn != 1 ">退料方式：{{ project.returnMode }}</div>
            <div style="flex: 1" v-if="hanleBtn == 3">
              调拨工程：{{ project.returnTo }}
            </div>
          </div>
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1">申请人：{{ project.realname }}</div>
            <div style="flex: 1">申请时间：{{ project.createTime }}</div>
          </div>
        </div>
        <p class="detil-code">
          {{ hanleBtn == 2 ? "库房退料审核信息" : "库房领料审核信息" }}
        </p>
        <div style="padding: 10px">
          <div style="display: flex; line-height: 50px" v-if="hanleBtn == 1">
            <div style="flex: 1; display: flex">
              <span style="margin-right: 15px">实际领料单据：</span>
              <div v-if="project.siteManagerUrl">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="project.siteManagerUrl.split(',')[0]"
                  :preview-src-list="project.siteManagerUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ project.siteManagerUrl.split(",").length }}张</span
                >
              </div>
            </div>
            <div style="flex: 1; display: flex">
              <span style="margin-right: 15px">领料照片：</span>
              <div v-if="project.receivePhotoUrl">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="project.receivePhotoUrl.split(',')[0]"
                  :preview-src-list="project.receivePhotoUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ project.receivePhotoUrl.split(",").length }}张</span
                >
              </div>
            </div>
          </div>
          <div
            style="display: flex; line-height: 50px"
            v-if="hanleBtn == 2 || hanleBtn == 3"
          >
            <div style="flex: 1; display: flex">
              <span style="margin-right: 15px">实际退料单据：</span>
              <div v-if="project.billUrl">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="project.billUrl.split(',')[0]"
                  :preview-src-list="project.billUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ project.billUrl.split(",").length }}张</span
                >
              </div>
            </div>
          </div>
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1">审核人：{{ project.warehouseKeeper }}</div>
            <div style="flex: 1">
              审核时间：{{ project.warehouseKeeperTime }}
            </div>
          </div>
          <div style="flex: 1">
            <div style="flex: 1">附加描述:{{ project.remarks }}</div>
          </div>
        </div>
        <p class="detil-code">{{ hanleBtn == 1 ? "发料信息" : "退料信息" }}</p>
        <div style="padding: 10px">
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1">
              {{ hanleBtn == 1 ? "发料人：" : "退料人："
              }}{{ project.warehouseStaff }}
            </div>
            <div style="flex: 1">
              {{ hanleBtn == 1 ? "发料时间：" : "退料时间："
              }}{{ project.warehouseStaffTime }}
            </div>
          </div>
          <div>
            <el-table border :data="tableDataChi" style="width: 100%">
              <el-table-column type="index" label="序号" width="80"> </el-table-column>
              <el-table-column label="材料编码" prop="materialCode"> </el-table-column>
              <el-table-column prop="materialName" label="材料名称" width="180">
              </el-table-column>
               <el-table-column prop="materialStyle" label="规格型号" width="180">
              </el-table-column>
              <el-table-column prop="unit" label="主计量单位" >
              </el-table-column>
              <el-table-column
                v-if="hanleBtn == 1"
                prop="inAmount"
                label="数量"
              >
              </el-table-column>
              <el-table-column
                v-if="hanleBtn == 2 || hanleBtn == 3"
                prop="outAmount"
                label="数量"
              >
              </el-table-column>
            </el-table>
            <el-pagination
              :current-page="form.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="form.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="projectTotal"
              @size-change="handleSizeChangeVib"
              @current-change="handleCurrentChangeVib"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detildialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  provideWarehouseRecord,
  grantRecord,
  directWarehouseRecord,
  directReturnRecord,
  allotWarehouseRecord,
  returnToRecord,
} from "../../../RequestPort/cost/cost";
export default {
  name: "dataTabel",
  props: {
    hanleBtn: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      detildialogVisible: false,
      time: [],
      searchBox: {
        current: 1,
        size: 100,
        timeType: 1,
      },
      form: {
        current: 1,
        size: 100,
        applyId: null,
      },
      total: null,
      tableData: [],
      tableDataChi: [],
      project: {},
      projectTotal: null,
      dataList: [
        { label: "审核时间", value: 1 },
        { label: "确认时间", value: 2 },
        { label: "提交时间", value: 3 },
      ],
      faliaoList: [
        { label: "提交时间", value: 1 },
        { label: "库房审核时间", value: 2 },
      ],
      tuiliaoList: [
        { label: "未退料", value: 1 },
        { label: "退料中", value: 2 },
        { label: "退料完成", value: 3 },
      ],
      chargeList: [
        { label: "是", value: 1 },
        { label: "否", value: 2 },
      ],
    };
  },
  mounted() {
    // if (this.time.length > 0) {
    //   this.searchBox.startTime = this.time[0];
    //   this.searchBox.endTime = this.time[1];
    // }
    // if (this.hanleBtn == 1) {
    //   //数据员处理记录
    // } else {
    //   this.loadList(this.searchBox);
    // }
    this.loadList(this.searchBox);
  },
  methods: {
    timeChange(val) {
      if (val) {
        this.searchBox.startTime = val[0];
        this.searchBox.endTime = val[1];
      } else {
        this.searchBox.startTime = null;
        this.searchBox.endTime = null;
      }
    },
    async showDetil(row) {
      this.form.applyId = row.id;
      await this.getGrantRecord();
      this.detildialogVisible = true;
    },

    getGrantRecord() {
      let { hanleBtn, form } = this;
      if (hanleBtn == 1) {
        grantRecord(form).then((res) => {
          this.project = res.data;
          this.tableDataChi = res.data.materialRecordPageBO.records;
          this.projectTotal = res.data.materialRecordPageBO.total;
        });
      } else if (hanleBtn == 2) {
        directReturnRecord(form).then((res) => {
          this.project = res.data;
          this.tableDataChi = res.data.materialRecordPageBO.records;
          this.projectTotal = res.data.materialRecordPageBO.total;
        });
      } else if (hanleBtn == 3) {
        returnToRecord(form).then((res) => {
          this.project = res.data;
          this.tableDataChi = res.data.materialRecordPageBO.records;
          this.projectTotal = res.data.materialRecordPageBO.total;
        });
      }
    },
    loadList(obj) {
      let { hanleBtn } = this;
      if (hanleBtn == 1) {
        provideWarehouseRecord(obj).then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        });
      } else if (hanleBtn == 2) {
        directWarehouseRecord(obj).then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        });
      } else if (hanleBtn == 3) {
        allotWarehouseRecord(obj).then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        });
      }
    },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList(this.searchBox);
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList(this.searchBox);
    },
    handleSizeChangeVib(val) {
      this.form.current = 1;
      this.form.size = val;
      this.getGrantRecord();
    },
    handleCurrentChangeVib(val) {
      this.form.current = val;
      this.getGrantRecord();
    },
    search() {
      this.loadList(this.searchBox);
    },
  },
};
</script>

<style scoped>
.SearchBar {
  border-radius: 5px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.detil-code {
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
</style>